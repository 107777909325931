.app-header {
  padding-right: 24px;
}

.menu-button {
  padding: 0 24px;
  cursor: pointer;
}

.ant-avatar {
  margin: 0 !important;
}

.ant-breadcrumb li {
  align-items: center;
  display: flex;
}

.ant-btn.logout-button {
  background: transparent;
  border: none;
  color: #ffeb3b;
  margin: 0 -8px 0 12px;
}

.ant-btn.logout-button:active,
.ant-btn.logout-button:focus,
.ant-btn.logout-button:hover {
  background: rgba(255, 255, 255, .1);
  color: #ffeb3b;
}
