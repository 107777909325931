@import '~antd/dist/antd.css';
@import '~react-big-calendar/lib/css/react-big-calendar.css';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles.css';
@import '~antd/es/modal/style/index.css';
@import '~antd/es/slider/style/index.css';
@import '~codemirror/lib/codemirror.css';
@import '~codemirror/theme/material.css';
@import './custom.css';

:root {
  --base-radius: 4px;
  --main-wrapper-heigth: 0px;
  --widget-purple: #7333fb;
  --widget-orange: #ff8f63;
  --widget-yellow: #ffca39;
  --widget-green: #42bb56;
  --widget-main: #fff;
  --widget-sidebar: #edf2fb;
}

.avatar-uploader {
  width: auto;
  margin: 0 auto;
  display: block;
}

.ant-table-small {
  max-height: calc(var(--main-wrapper-heigth));
  overflow: hidden;
}

.ant-table-body {
  max-height: calc(var(--main-wrapper-heigth) - 120px) !important;
}

.ant-drawer-body {
  padding: 0;
}

.reservation-drawer > .ant-drawer-content {
  background: #001529;
}

.reservation-drawer {
  background: #ffffff;
}

.ant-picker-now-btn {
  display: none;
}

@media only screen and (max-width: 800px) {
  .ant-layout-content {
    padding: 24px !important;
  }
  .phone-button-margin {
    margin: 0 24px;
    width: 100%;
  }
  .phone-button-margin-full {
    margin-left: 24px;
    width: calc(100% - 48px) !important;
  }
  .ant-tabs-nav-wrap {
    margin: 0 24px;
  }
  .mobile-left-margin {
    margin-left: 24px;
  }
  .mobile-left-padding {
    padding-left: 24px;
  }
  .ant-picker-now-btn {
    display: none;
  }
}

.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-disabled.ant-select-multiple .ant-select-selector,
.ant-picker.ant-picker-disabled,
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.65);
  background: rgba(245, 245, 245, 0.33);
}

.site-page-header {
  border: 1px solid rgba(0, 0, 0, 0.22);
}

.no-arrows-input > .ant-input-number-handler-wrap {
  display: none;
}

.main-drawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body {
  background: #001529;
}

.spin-ant-d {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

.adding-margin {
  margin-top: 20px;
}

.spin-container {
  text-align: center;
  width: 100%;
}

.ant-menu-submenu-popup {
  background: #f6f7fa !important;
}

.ant-btn-primary.ant-btn-circle {
  border-radius: 50%;
}



.flex { display: flex; }
.flex-column { flex-direction: column; }
.flex-grow-1 { flex-grow: 1; }
.flex-shrink-0 { flex-shrink: 0; }
.flex-nowrap { flex-wrap: nowrap; }

.align-items-center { align-items: center; }

.justify-content-space-between { justify-content: space-between; }

.text-right { text-align: right; }
.text-wrap { white-space: normal; }
.text-18 { font-size: 18px; }
.text-bold { font-weight: bold; }

.mx-1 { margin-left: 4px; margin-right: 4px; }
.mx-2 { margin-left: 8px; margin-right: 8px; }
.mx-3 { margin-left: 16px; margin-right: 16px; }
.mx-4 { margin-left: 24px; margin-right: 24px; }
.mb-1 { margin-bottom: 4px; }
.mb-2 { margin-bottom: 8px; }
.mb-3 { margin-bottom: 16px; }
.mb-4 { margin-bottom: 24px; }
.ml-1 { margin-left: 4px; }
.ml-2 { margin-left: 8px; }
.ml-3 { margin-left: 16px; }
.ml-4 { margin-left: 24px; }
.mr-1 { margin-right: 4px; }
.mr-2 { margin-right: 8px; }
.mr-3 { margin-right: 16px; }
.mr-4 { margin-right: 24px; }
.mt-1 { margin-top: 4px; }
.mt-2 { margin-top: 8px; }
.mt-3 { margin-top: 16px; }
.mt-4 { margin-top: 24px; }

.p-0 { padding: 0; }
.px-1 { padding-left: 4px !important; padding-right: 4px !important; }
.px-2 { padding-left: 8px !important; padding-right: 8px !important; }
.px-3 { padding-left: 16px !important; padding-right: 16px !important; }
.px-4 { padding-left: 24px !important; padding-right: 24px !important; }
.py-1 { padding-bottom: 4px !important; padding-top: 4px !important; }
.py-2 { padding-bottom: 8px !important; padding-top: 8px !important; }
.py-3 { padding-bottom: 16px !important; padding-top: 16px !important; }
.py-4 { padding-bottom: 24px !important; padding-top: 24px !important; }

.w-full { width: 100%; }

.CodeMirror.cm-s-default {
  margin-top: 0;
}
