.statistics-card {
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, .1);
  height: 164px;
  overflow: hidden;
  position: relative;
}

.statistics-card--chart {
  height: 100px;
  margin: -5px -5px 0;
  padding-top: 5px;
}
