.ant-btn.prev-buttom {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-btn.today-button {
  border-radius: 0;
  margin: 0 -1px;
}

.ant-btn.next-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-btn:hover {
  z-index: 1;
}
