/* 
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.checkout-form .StripeElement,
.checkout-form .StripeElementEPS,
.checkout-form .StripeElementFpx,
.checkout-form .StripeElementIdeal,
.checkout-form .StripeElementP24 {
  background: white;
  border: 1px solid #eaebff;
  border-radius: 4px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
  display: block;
  max-width: 500px;
  padding: 10px 14px;
}

.checkout-form .StripeElement--focus,
.checkout-form .StripeElementEPS--focus,
.checkout-form .StripeElementFpx--focus,
.checkout-form .StripeElementIdeal--focus,
.checkout-form .StripeElementP24--focus {
  border-color: hsla(210, 96%, 45%, 50%);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.checkout-form .StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.checkout-form .StripeElementEPS,
.checkout-form .StripeElementFpx,
.checkout-form .StripeElementIdeal,
.checkout-form .StripeElementP24 {
  padding: 0;
}
