.rbc-toolbar {
  display: none;
}
.rbc-header {
  padding: 10px 0;
  background-color: #fafafa;
  border-bottom: none;
}
.rbc-time-header-content.rbc-time-header-content {
  border: none;
}
.rbc-time-content,
.rbc-time-view,
.rbc-time-view-resources {
  max-height: calc(var(--main-wrapper-heigth) - 80px) !important;
  border: 1px solid #f0f0f0;
}
.rbc-timeslot-group {
  border-bottom: 1px solid #f0f0f0;
  background: transparent;
}
.rbc-time-content > * + * > * {
  border-left: 1px solid #f0f0f0;
}
.rbc-today {
  background: white;
}
.rbc-allday-cell {
  display: none;
}
.rbc-time-view-resources {
  background-color: #ffffff !important;
}
.rbc-label {
  font-weight: 300;
}
.rbc-time-header-gutter {
  background-color: #fafafa !important;
}
.rbc-addons-dnd-resize-ns-anchor {
  display: none;
}
.rbc-event {
  border-radius: 2px;
  border: 1px solid #c9c9c9 !important;
  background-color: rgba(205, 205, 205, 0.35);
  border-left: 5px solid #575757 !important;
  color: #000c17;
  margin-left: 5px;
}

.rbc-label {
  font-size: 13px;
}
.rbc-addons-dnd-dragged-even {
  background-color: green;
}
.custom-reservation > .rbc-event {
  background-color: #fafafa;
  border-left: 5px solid blue !important;
}

.custom-reservation-created > .rbc-event {
  background-color: rgba(24, 144, 255, 0.35);
  border-left: 5px solid #1890ff !important;
}

.custom-reservation-done > .rbc-event {
  background-color: rgba(124, 179, 5, 0.35);
  border-left: 5px solid #7cb305 !important;
}

.custom-reservation-inProgress > .rbc-event {
  background-color: rgba(8, 151, 156, 0.35);
  border-left: 5px solid #08979c !important;
}

.custom-reservation-canceled > .rbc-event {
  background-color: rgba(212, 56, 13, 0.35);
  border-left: 5px solid #d4380d !important;
}

.custom-reservation-needUpdate > .rbc-event {
  background-color: rgba(196, 29, 127, 0.35);
  border-left: 5px solid #c41d7f !important;
}

.rbc-current-time-indicator {
  height: 3px;
  opacity: 0.3;
}
/*border-bottom: 1px solid #f0f0f0;*/

.rbc-selected[title] {
  pointer-events: none;
}
.rbc-slot-selection {
  display: none;
}
.custom-reservation-selected {
  outline: none;
  border-color: #9ecaed !important;
  box-shadow: 0 0 10px #9ecaed !important;
}



.rbc-time-header-gutter {
  max-width: 67px !important;
  width: 67px !important;
}

.rbc-row-resource {
  flex-grow: 1;
}

.rbc-time-gutter .rbc-timeslot-group {
  width: 65px;
}

.calendar--empty .rbc-time-view-resources {
  display: none !important;
}
