.skeedee-calendar-header {
  align-items: center;
  display: flex;
  padding: 4px;
}

.skeedee-calendar-header button {
  background: none;
  border: none;
  cursor: pointer;
  flex-shrink: 0;
  height: 28px;
  width: 28px;
}

.skeedee-calendar-header button:hover {
  background: #f5f5f5;
}

.skeedee-calendar-header-view {
  flex-grow: 1;
  text-align: center;
}
