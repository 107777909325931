.select-shop-button {
  width: 100%;
  height: 54px;
  background: transparent;
  color: white;
  border: none;
}

.select-shop-button:active,
.select-shop-button:focus,
.select-shop-button:hover {
  background: transparent;
}
